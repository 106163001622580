import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

import * as modalContentKeys from "Flocknote/GlobalComponents/Modal/contentKeys";
import { useFlocknoteAppState } from "Flocknote/state";

import defaultObject from "Utilities/defaultObject";
import { fnReportError } from "Utilities/fnReportError";

import { flocknoteKeys } from "./API/keys";
import { useGetNetworkMetadata } from "./API/queries";
import flocknoteActions from "./actions";
// TODO: import all instances of this directly from apiHooks
import { useFundItStatus, useNetworkMetadata, useUserData } from "./apiHooks";

const modalContentKeysByValue = Object.fromEntries(
  Object.values(modalContentKeys).map((elem) => [elem, elem])
);

const useNetworkDataSlicePatch = (key) => {
  const queryClient = useQueryClient();
  const { data: { [key]: slice } = {} } = useGetNetworkMetadata();
  const patch = useCallback(
    (value) => {
      const current = queryClient.getQueryData(flocknoteKeys.network());
      queryClient.setQueryData({
        [key]: value,
        ...current,
      });
      queryClient.invalidateQueries(flocknoteKeys.network());
    },
    [key, queryClient]
  );
  return [slice, patch];
};

/**
 * [getter, setter] for whether or not the network should be sold Flocknote People.
 * @returns {[boolean, function]}
 */
const useShowPeopleSplash = () => useNetworkDataSlicePatch("showPeopleSplash");

/**
 * [getter, setter] for whether or not the network has used their flocknote people trial.
 * @returns {[boolean, function]} */
const useHasUsedFPTrial = () => useNetworkDataSlicePatch("hasUsedFPTrial");

/**
 * [getter, setter] for the groupId set to global context by the legacy code.
 * @returns {[number, function]}
 */
const useGroupId = () => {
  const [{ groupId }, dispatch] = useFlocknoteAppState();

  const setGroupId = useCallback(
    (newGroupId) => dispatch(flocknoteActions.setGroupId(newGroupId)),
    [dispatch]
  );

  return [groupId, setGroupId];
};

/**
 * [getter, setter] for networkType in global context. Used when network changes their type from the setting sidesheet.
 * @returns {[number, function]}
 */
const useNetworkType = () => useNetworkDataSlicePatch("networkType");

/**
 * [getter, setter] for networkName in global context..
 * @returns {[string, function]}
 */
const useNetworkName = () => useNetworkDataSlicePatch("networkName");

/**
 * [getter, setter] for hasAddOnGifts in global context.
 * @returns {[number, function]}
 */
const useHasAddOnGifts = () => useNetworkDataSlicePatch("hasAddOnGifts");

const useIsClosedNetwork = () => {
  const [networkType] = useNetworkType();
  return networkType === "closed";
};

/**
 * @typedef ModalState
 * @property {boolean} open - is the modal open?
 * @property {string} contentKey - the key to the current modal contents (see src/Flocknote/GlobalComponents/Modal/Modal.js).
 * @property {object} contentProps - the props to be passed to the component being render by the modal.
 */

/**
 * @typedef OpenModal - opens the modal with the given contentkey and contentProps.
 * @type {function}
 * @param {string} contentKey
 * @param {object} contentProps
 */

/**
 * @typedef ModalApi
 * @property {function} closeModal - closes the modal and clears the contentKey and contentProps
 * @property {OpenModal} openModal
 */

/**
 * returns state and interface to interact with the global modal component.
 *
 * @returns {[ModalState, ModalApi]}
 */
const useModal = () => {
  const [
    {
      modal: { isOpen, contentKey, contentProps },
    },
    dispatch,
  ] = useFlocknoteAppState();

  const closeModal = useCallback(() => {
    dispatch(flocknoteActions.closeModal());
  }, [dispatch]);

  const openModal = useCallback(
    // pass in onClose as a special contentProp to fire whatever you need when the modal closes:
    // openModa('someKey',' {onClose: () => {}})
    (newContentKey, newContentProps) => {
      if (modalContentKeysByValue[newContentKey]) {
        dispatch(flocknoteActions.openModal(newContentKey, newContentProps));
      } else {
        fnReportError({
          message: `${newContentKey} was not found in Flocknote/GlobalComponents/Modal/contentKeys. Expected one of:\n\t${Object.values(
            modalContentKeysByValue
          ).join("\n\t")}`,
        });
      }
    },
    [dispatch]
  );

  const modal = {
    isOpen: useMemo(() => isOpen, [isOpen]),
    contentKey: useMemo(() => contentKey, [contentKey]),
    contentProps: useMemo(() => contentProps, [contentProps]),
  };

  return [modal, { closeModal, openModal }];
};

const useModalUrlParams = () => {
  // https://testnet.flocknote.docker/give?contentKey=ALERT_EDITOR&contentProps={%22glossary%22:{%22title%22:%22example%22}}
  const [{ modalData }] = useFlocknoteAppState();
  const [, { openModal }] = useModal();

  const init = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const contentKey = urlParams.get("contentKey");
    const contentProps = urlParams.get("contentProps");

    if (contentKey) {
      let contentPropsParsed;
      try {
        contentPropsParsed = JSON.parse(contentProps) || {};
      } catch (e) {
        fnReportError({
          message: `Unable to parse contentProps argument for ${contentKey} modal: ${contentProps}`,
        });
      }
      openModal(contentKey, contentPropsParsed);

      // Replace the history state so that the URL doesn't have the ugly modal params in it.
      urlParams.delete("contentKey");
      urlParams.delete("contentProps");

      const newParams = urlParams.toString();
      window.history.replaceState(
        {},
        document.title,
        `${window.location.pathname}${newParams ? `?${newParams}` : ""}`
      );
    }
  }, [openModal]);

  return [modalData || {}, init];
};

const useUserProfile = () => {
  const [{ userProfile }] = useFlocknoteAppState();

  // TODO: return fuction to init this state too.
  return [userProfile];
};

const useIsSupderDuper = () => {
  const [{ isSuperDuper }] = useUserData();
  return isSuperDuper;
};

const useUserID = () => {
  const [{ userID }] = useUserData();
  return userID;
};

const useUserPhoneInfo = () => {
  const [{ phoneInfo }] = useUserData();
  return phoneInfo || defaultObject;
};

const useUserHasPhone = () => {
  const { phone } = useUserPhoneInfo();
  return Boolean(phone);
};

/**
 * getter and setter for whether or not the network has disabled payment display.
 * @returns {[boolean, function]} */
const useHasHideMakeAPaymentButton = () =>
  useNetworkDataSlicePatch("hideMakeAPaymentButton");

const useCanStax = () => {
  const [{ canStax }] = useNetworkMetadata();
  return canStax;
};

const useIsSuperAdmin = () => {
  const [{ isSuperAdmin }] = useUserData();
  return isSuperAdmin;
};

const useIsSuperOrGroupAdmin = () => {
  const [{ isSuperAdmin, isGroupAdmin }] = useUserData();
  return isSuperAdmin || isGroupAdmin;
};

const useIsBillingAdmin = () => {
  const [{ isBillingAdmin }] = useUserData();
  return isBillingAdmin;
};

const useFunditPricing = () => {
  const [
    {
      value: { pricing },
    },
  ] = useFundItStatus();
  return pricing || defaultObject;
};

const useNoPhoneCodes = () => {
  const [data] = useNetworkMetadata();
  const countryCode = data?.address?.countryCode;

  if (!countryCode) return false;
  return !/^(us|ca)$/i.test(countryCode);
};

const useHasReports = () => {
  const [{ hasReports }] = useNetworkMetadata();
  return hasReports;
};

const flocknoteStateHooks = {
  useShowPeopleSplash,
  useHasUsedFPTrial,
  useGroupId,
  useNetworkMetadata,
  useModal,
  useNetworkType,
  useIsClosedNetwork,
  useHasAddOnGifts,
  useUserProfile,
};

// TODO: do them all like this from now on so auto import is easy.
export {
  useIsSupderDuper,
  useUserID,
  useModalUrlParams,
  useHasHideMakeAPaymentButton,
  useCanStax,
  useUserPhoneInfo,
  useUserHasPhone,
  useNetworkName,
  useIsSuperAdmin,
  useIsSuperOrGroupAdmin,
  useIsBillingAdmin,
  useFunditPricing,
  useModal,
  useNoPhoneCodes,
  useHasReports,
};

export default flocknoteStateHooks;
